<template>
  <div class="w-100">
    <pibot-page-heading>
      Dashboard - KPI overview
    </pibot-page-heading>

    <v-container>
      <section>
        <v-row>
          <v-col
            :cols="12"
            :md="4"
            class="py-0"
          >
            <pibot-chartist-donut
              :data="riskDistribution"
              :colorScheme="fiveColorScheme"
              :donutWidth="11"
              :chartPadding="30"
              :sliceMargin="6"
              :height="300"
              :width="250"
              orient="right"
            >
              <template #title>
                Risk status distribution
              </template>
            </pibot-chartist-donut>
          </v-col>

          <v-col
            :cols="12"
            :md="4"
            class="py-0"
          >
            <pibot-chartist-donut
              :data="likelihoodDistribution"
              :colorScheme="sixColorScheme"
              :donutWidth="11"
              :chartPadding="30"
              :sliceMargin="6"
              :height="300"
              :width="250"
              orient="right"
            >
              <template #title>
                Likelihood status distribution
              </template>
            </pibot-chartist-donut>
          </v-col>

          <v-col
            :cols="12"
            :md="4"
            class="py-0"
          >
            <pibot-chartist-donut
              :data="consequenceDistribution"
              :colorScheme="sixColorScheme"
              :donutWidth="11"
              :chartPadding="30"
              :sliceMargin="6"
              :height="300"
              :width="250"
              orient="right"
            >
              <template #title>
                Consequence status distribution
              </template>
            </pibot-chartist-donut>
          </v-col>
        </v-row>
      </section>

      <!-- <v-divider class="my-3" /> -->

      <section>
        <v-row align="stretch">
          <v-col
            :cols="12"
            :md="6"
            class="py-0"
          >
            <pibot-chartist-bar
              yKey="length"
              xKey="product"
              yLabel="Length [m]"
              xLabel="Product"
              :labels="lengthPerProductLabels"
              :data="lengthPerProductChartData"
              :height="400"
              colorScheme="schemeCategory10"
            >
              <template #title>
                Metres of pipelines per product
              </template>

              <template #title-append>
                <v-icon color="grey">functions</v-icon>
                {{ lengthPerProductSum }} [m]
              </template>
            </pibot-chartist-bar>
          </v-col>

          <v-col
            :cols="12"
            :md="6"
            class="py-0"
          >
            <pibot-chartist-bar
              xKey="group"
              :stack-data="cpChartData"
              :labels="cpLabels"
              :legend="cpLegend"
              :height="400"
              :colorScheme="threeColorScheme"
              orient="right"
            >
              <template #title>
                Overall CP status per group
              </template>
            </pibot-chartist-bar>
          </v-col>
        </v-row>
      </section>

      <!-- <v-divider class="my-3" />

      <section>
        <v-row>
          <v-col
            :cols="12"
            :md="6"
            class="py-0"
          >
            <pibot-bar-plot-basic
              :height="400"
              :width="850"
              colorScheme="schemeCategory10"
            >
              <template #title>
                Lorem ipsum...
              </template>
            </pibot-bar-plot-basic>
          </v-col>

          <v-col
            :cols="12"
            :md="6"
            class="py-0"
          >
            <pibot-bar-plot-basic
              :height="400"
              :width="850"
              colorScheme="schemeCategory10"
            >
              <template #title>
                ...dolor sit amet
              </template>
            </pibot-bar-plot-basic>
          </v-col>
        </v-row>
      </section> -->
    </v-container>
  </div>
</template>

<script>
import PageHeading from '@/components/typography/headings/PageHeading'
import styleMixin from '@/utils/mixins/styling/style.mixin'

export default {
  name: 'pibot-dashboard',
  mixins: [styleMixin],
  components: {
    'pibot-page-heading': PageHeading,
    'pibot-chartist-donut': () => import('@/components/charts/DonutChartist'),
    'pibot-chartist-bar': () => import('@/components/charts/BarChartist')
  },
  data: () => {
    return {
      riskDistribution: {
        'Very Low': 8,
        Low: 2,
        Medium: 2,
        High: 0,
        'Very High': 0
      },
      likelihoodDistribution: {
        Rare: 6,
        Remote: 5,
        Unlikely: 1,
        Seldom: 2,
        Occasional: 0,
        Likely: 0
      },
      consequenceDistribution: {
        Incidental: 11,
        Minor: 11,
        Moderate: 6,
        Major: 0,
        Severe: 0,
        Catastrophic: 0
      },
      lengthPerProduct: [
        {
          product: 'Dry Gas',
          length: 128300
        },
        {
          product: 'Crude Oil',
          length: 139000
        },
        {
          product: 'Water',
          length: 53300
        },
        {
          product: 'Hydrogen',
          length: 0
        },
        {
          product: 'Decom.',
          length: 37300
        }
      ],
      cp: [
        {
          group: 2016,
          'Under Protected': 6,
          Protected: 32,
          'Over protected': 12
        },
        {
          group: 2017,
          'Under Protected': 11,
          Protected: 31,
          'Over protected': 8
        },
        {
          group: 2018,
          'Under Protected': 2,
          Protected: 25,
          'Over protected': 23
        },
        {
          group: 2019,
          'Under Protected': 5,
          Protected: 30,
          'Over protected': 15
        },
        {
          group: 2020,
          'Under Protected': 8,
          Protected: 29,
          'Over protected': 13
        }
      ]
    }
  },
  // methods: {
  //   updateData () {
  //     const cpTemp = []
  //     for (var i = 0; i < this.cp.length; i++) {
  //       const up = Math.floor(Math.random() * 11)
  //       const op = Math.floor(Math.random() * 31)
  //       cpTemp[i] = {
  //         group: i + 2016,
  //         'Under Protected': up,
  //         Protected: 50 - up - op,
  //         'Over protected': op
  //       }
  //     }
  //     this.cp = cpTemp
  //   }
  // },
  computed: {
    cpLabels () {
      return [...new Set(this.cp.map(o => String(o.group)))]
    },
    cpLegend () {
      return Object.keys(this.cp[0]).filter(k => k !== 'group')
    },
    cpChartData () {
      if (!this.cp) return null

      const groups = [...new Set(this.cp.map(o => o.group))]
      const valueKeys = Object.keys(this.cp[0]).filter(k => k !== 'group')

      const dataMap = {}
      groups.forEach(g => {
        dataMap[g] = this.cp.filter(o => o.group === g)
      })

      const stacked = []

      // The following code will create an Array like this:
      // [ `series`
      //   [ `key`
      //     { `group/group`
      //       meta,
      //       value
      //     }
      //   ]
      // ]

      valueKeys.forEach((k) => {
        const stackGroup = []

        groups.forEach((g) => {
          // Create an Array of Objects for all data Objects of the current group
          const toPush = dataMap[g].map(o => ({
            meta: String(g),
            value: o[k]
          }))

          stackGroup.push(...toPush)
        })

        stacked.push(stackGroup)
      })

      return stacked
    },
    lengthPerProductLabels () {
      return [...new Set(this.lengthPerProduct.map(o => String(o.product)))]
    },
    lengthPerProductChartData () {
      if (!this.lengthPerProduct) return null

      const chartData = this.lengthPerProduct.map(o => ({
        meta: o.product,
        value: o.length
      }))

      return [chartData]
    },
    lengthPerProductSum () {
      return this.lengthPerProduct.map(o => o.length).reduce((a, c) => a + c).toLocaleString()
    },
    threeColorScheme () {
      return [
        this.themeColors.error.darken2,
        this.themeColors.warning.darken2,
        this.themeColors.success.darken2
      ]
    },
    fiveColorScheme () {
      return [
        this.themeColors.success.darken2,
        this.themeColors.success.base,
        this.themeColors.warning.darken2,
        this.themeColors.error.base,
        this.themeColors.error.darken2
      ]
    },
    sixColorScheme () {
      return [
        this.themeColors.success.darken2,
        this.themeColors.success.base,
        this.themeColors.warning.base,
        this.themeColors.warning.darken2,
        this.themeColors.error.base,
        this.themeColors.error.darken2
      ]
    }
  }
}
</script>
